import axios from 'axios';
import {mapState} from 'vuex';

export default {
    data: function () {
        return {
            toasted_options: {
                success: {
                    position: 'top',
                    type: 'success',
                },
                error: {
                    position: 'top',
                    type: 'error',
                },
                info: {
                    duration: '8000',
                    icon: 'info',
                    iconPack: 'fontawesome',
                    position: 'top-right',
                    type: 'info',
                    singleton: true,
                },
            },
            axiosInstance: null,
            currentUserId: null,
            currentCoach: null,
            orgInfo: null,
        };
    },
    computed: {
        ...mapState('profile', ['userPermissions', 'currentUser']),
        ...mapState('programMgmt', ['program_management_permission']),
        validUser() {
            if (this.currentUser) {
                return this.currentUser;
            } else {
                return {active_member: {}};
            }
        },
        canAddEditProgram() {
            return this.userPermissions.find(
                (x) => x.name === 'Can add/edit programs' && x.active
            );
        },
        canDeleteProgram() {
            return this.userPermissions.find(
                (x) => x.name === 'Can delete programs' && x.active
            );
        },
        canAddEditBilling() {
            return (
                !(
                    this.organization?.super_organization_id ||
                    this.organization?.is_super
                ) &&
                this.userPermissions.find(
                    (x) => x.name === 'Can add/edit billing' && x.active
                )
            );
        },
        canEditOrgSettings() {
            return this.userPermissions.find(
                (x) => x.name === 'Can edit organizations settings' && x.active
            );
        },
        canAccessAppSettings() {
            return (
                !(
                    this.organization?.super_organization_id ||
                    this.organization?.is_super
                ) &&
                this.userPermissions.find(
                    (x) => x.name === 'Can access App Store' && x.active
                )
            );
        },
        canAddEditMetrics() {
            return this.userPermissions.find(
                (x) => x.name === 'Can add/edit metrics' && x.active
            );
        },
        canAddEditResources() {
            return this.userPermissions?.find(
                (x) => x.name === 'Can add/edit resources' && x.active
            );
        },
        webEnvUrl() {
            let url = window.location.href;
            let envUrl = url.replace(window.location.pathname, '').replace('#/', '');
            return envUrl;
        },
    },
    methods: {
        async authenticate(func) {
            try {
                const response = await func.call();
                // This is for a redirect, but the data will not exist from server side authentication
                return response;
            } catch (e) {
                console.log(e);
                console.log(JSON.stringify(e));
                //window.location.href = '/';
            }
        },
        async getCurrentUserId() {
            const response = await axios.get(this.sessionUrl + 'get_current_user_id');
            this.currentUserId = response.data.result;
        },
        async getCurrentCoach() {
            const response = await axios.get(this.sessionUrl + 'get_current_coach');
            this.currentCoach = response.data.result;
        },
        async getCurrentUser() {
            const response = await axios.get(this.sessionUrl + 'get_current_user');
            this.$store.commit('profile/setCurrentUser', response.data.result);
        },
        async getUserPermissions() {
            const response = await this.authenticate(async () => {
                return await axios.get(this.userUrl + 'get_user_permissions');
            });
            this.$store.commit('profile/setUserPermissions', response.data.result);
        },
        async getProgramManagementPermissions() {
            let response = await axios.get(
                this.organizationSettingsUrl + 'get_permission/program_management'
            );
            this.$store.commit(
                'programMgmt/setProgramManagmentPermission',
                response.data.result
            );
        },
        hasCommunitySettings(user) {
            return user && user.has_communities_setting;
        },
        showResponse(
            response,
            successMessage,
            errorMessage,
            isSuccessPositive = true
        ) {
            if (response.data.success) {
                let options = this.toasted_options.success;
                options = {
                    ...options,
                    icon: 'check-circle',
                    closeOnSwipe: true,
                    duration: 2000,
                };
                !isSuccessPositive
                    ? (options = {...options, icon: 'times-circle', type: 'error'})
                    : 0;
                this.$toast.show(
                    successMessage ? successMessage : 'Save Successful',
                    options
                );
            } else {
                this.$toast.show(
                    errorMessage ? errorMessage : 'Error Saving',
                    this.toasted_options.error
                );
            }
        },
    },
};
